import request from '@/utils/request'


// 查询店铺列表
export function listShop(query) {
  return request({
    url: '/shop/shop/list',
    method: 'get',
    params: query
  })
}

// 查询店铺分页
export function pageShop(query) {
  return request({
    url: '/shop/shop/page',
    method: 'get',
    params: query
  })
}

// 查询店铺详细
export function getShop(data) {
  return request({
    url: '/shop/shop/detail',
    method: 'get',
    params: data
  })
}

// 新增店铺
export function addShop(data) {
  return request({
    url: '/shop/shop/add',
    method: 'post',
    data: data
  })
}

// 修改店铺
export function updateShop(data) {
  return request({
    url: '/shop/shop/edit',
    method: 'post',
    data: data
  })
}

// 删除店铺
export function delShop(data) {
  return request({
    url: '/shop/shop/delete',
    method: 'post',
    data: data
  })
}
// 是否推荐
export function updateShopRecommend(data) {
  return request({
    url: '/shop/shop/updateShopRecommend',
    method: 'post',
    data: data
  })
}
// 是否发布研学活动
export function updateIsStudy(data) {
  return request({
    url: '/shop/shop/updateIsStudy',
    method: 'post',
    data: data
  })
}
// 平台客服
export function ptkf(data) {
  return request({
    url: '/shop/shop/ptkf',
    method: 'get',
    params: data
  })
}
